<template>
  <v-container fluid>
    <v-layout wrap justify-center >
      <v-card v-if="$getters.userInfo.grade < 'UT06'" class="ma-0 mt-0" outlined color="transparent">
          <InputEvent 
          class="pa-0"/>
      </v-card>
      <v-card class="ma-0" outlined color="transparent">
        <Table 
            class="pa-0"/>
      </v-card>
    </v-layout>
 </v-container>
</template>

<script>
import Vue from 'vue'
import Apis from '@/api/apis'

import InputEvent from './child/InputEvent.vue';
import Table from './child/Table.vue';
export const EventBus = new Vue()

export default {
  name: 'TotalEvents',
  components: {
    InputEvent,
    Table
},
  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
  },
  // data () {

  // },
}
</script>
<style lang="scss" scoped >

</style>
