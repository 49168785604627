<template>
  <v-container>
    <v-card width="400"  height="657" class="mx-auto" elevation="3">
      <v-form id=farm >
        <v-card-text >
          <div class="ml-2 eventid">{{event_id}}</div>
              <v-select dense outlined class="ma-1" style="height: 40px; width: auto;"
                id="building" 
                ref="building" 
                label="축사선택" 
                v-model="building"
                :items="buildingItems"
                :menu-props="{ top: false, offsetY: true }"
                no-data-text="자료(권한)이 없습니다."
                item-text="name"
                return-object
                @change="changeBuilding()"
                @focus="comboBuildings()"
                clearable
              />
              <v-select dense outlined class="ma-1 mt-2" style="height: 40px; width: auto;"
                id="room" 
                ref="room" 
                label="돈방선택" 
                v-model="room"
                :items="roomItems"
                :menu-props="{ top: false, offsetY: true }"
                no-data-text="자료(권한)이 없습니다."
                item-text="name"
                return-object
                @change="changeRoom()"
                @focus="comboRooms()"
                clearable
              />
              
          <v-layout ml-1 style="height: 35px; width: 225px; margin-top: 20px; ">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined clearable
                  id="eventdate" 
                  ref="eventdate" 
                  v-model="eventdate"
                  label="발생일"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title locale="ko-KR"
                v-model="eventdate"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-layout>
           <v-card class="ml-1 " style="height: auto;" >
            <v-radio-group dense outlined row 
              id="event" 
              v-model="event"
            >
              <v-radio class="ml-5 pt-3" style="height: 20px;"
                ref="event_even" label="입식" value="EVEN"  color="blue" 
              ></v-radio>
              <v-radio class="ml-0 pt-3" style="height: 20px;"
                label="이동" value="EVTR" color="cyan" 
              ></v-radio>
              <v-radio class="ml-0 pt-3" style="height: 20px;"
                label="출하" value="EVSD" color="green" 
              ></v-radio>
              <v-radio class="ml-0 pt-3" style="height: 20px;"
                label="도폐" value="EVDT" color="red" 
              ></v-radio>
            </v-radio-group>
          </v-card>
          <v-layout mt-2 id="result" >  
            <v-btn class="elevation-1 plus-btn ma-1 mb-1"
                @click="pigcnt > 0 ? pigcnt-- : pigcnt"
                >
              <v-icon color="black">mdi-minus</v-icon>
            </v-btn>
            <v-text-field dense outlined class="shrink my-field" style="height: 40px; width: 88px; margin: 5px; "
                type="number" 
                align="right"
                id="pigcnt" 
                ref="pigcnt" 
                v-model="pigcnt" 
                label="두수" 
                min="0"
                max="500"
                step="10"
                maxlength="3"
              />
            <v-btn class="elevation-1 plus-btn ma-1 mb-1"
                @click="pigcnt < 500 ? pigcnt++ : pigcnt"
                >
              <v-icon color="black">mdi-plus</v-icon>
            </v-btn>
          <v-layout id="result" v-show="event === 'EVEN'">  
            <v-btn class="elevation-1 plus-btn ma-1 mb-1 ml-6"
                @click="dayage > 0 ? dayage-- : dayage"
                >
              <v-icon color="black">mdi-minus</v-icon>
            </v-btn>
            <v-text-field dense outlined class="shrink my-field" style="height: 40px; width: 80px; margin: 5px; "
                type="number" 
                align="right"
                id="dayage" 
                ref="dayage" 
                v-model="dayage" 
                label="일령" 
                min="0"
                max="500"
                step="10"
                maxlength="3"
              />
            <v-btn class="elevation-1 plus-btn ma-1 mb-1"
                @click="dayage < 100 ? dayage++ : dayage"
                >
              <v-icon color="black">mdi-plus</v-icon>
            </v-btn>
          </v-layout>  
          </v-layout>  
          <v-card outlined color="transparent">
          <v-layout wrap v-show="event === 'EVTR'">
            <v-select dense outlined class="ma-1" style="height: 40px; width: 200px;"
              id="tobuilding" 
              ref="tobuilding" 
              label="이동축사선택" 
              v-model="tobuilding"
              :items="tobuildingItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="tochangeBuilding()"
              @focus="tocomboBuildings()"
              clearable
            />
            <v-select dense outlined class="ma-1" style="height: 40px; width: 200px;"
              id="toroom" 
              ref="toroom" 
              label="이동돈방선택" 
              v-model="toroom"
              :items="toroomItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="tochangeRoom()"
              @focus="tocomboRooms()"
              clearable
            />
          </v-layout>
          </v-card>
          <v-textarea class="ml-1 mt-2" outlined dense rows="2" name="input-7-4"
            label="메모"
            id="history" 
            ref="history" 
            v-model="history"
            value=""
            clearable
            persistent-hint :hint="hint"
            
          >
            <template v-slot:append>
              <v-dialog
                  v-model="dialogMemo"
                  max-width="400px"
                  style="top: -12px"
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                    mdi-comment-check
                    </v-icon>
                </template>
                <v-card>
                  <v-card-text class="pa-6">
                    최근 입력한 내용들 중 선택입력할 수 있습니다.
                    <v-select dense outlined style="height: 45px; margin: 5px"
                      id="memoHistory" 
                      ref="memoHistory" 
                      label="선택"
                      :items="memoHistories" 
                      placeholder="선택" 
                      v-model="memoHistory"
                      @focus="listMemo()"
                    />
                    <v-btn small text color="primary" @click="selectMemo">확인</v-btn>
                    <v-btn small text color="black" @click="cancelMemo">취소</v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
          </v-textarea>
        </v-card-text>
        <v-card-actions class="d-block" >
          <v-row justify= "center" >
            <v-btn class="rounded-pill elevation-7 normal-btn ma-2 mb-5" 
              :loading= "loadingSave" 
              id="save" 
              ref="save" 
              style="height: 35px; width: 80px;" 
              type="submit" 
              @click.prevent="save()">
              저장
            </v-btn>  
            <v-btn class="rounded-pill elevation-7 new-btn ma-2 mb-5" 
              id="newRegister" 
              ref="newRegister" 
              style="height: 35px; width: 80px;" 
              @click="newRegister()">
              새로등록
            </v-btn>
            <v-btn class="rounded-pill elevation-7 del-btn ma-2 mb-5" 
              id="newRegister" 
              ref="newRegister" 
              style="height: 35px; width: 80px;" 
              v-show="event_id > 0"
              @click="deleteEvent()">
              삭제
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>

  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import { EventBus } from '../TotalEvents.vue'

export default {
  name: 'InputEvent',

  data: () => ({
    event_id : "0",
    building : {},
    buildingItems : [],
    room : {},
    roomItems : [],
    tobuilding : {},
    tobuildingItems : [],
    toroom : {},
    toroomItems : [],
    event: "",
    eventdate: "",
    pigcnt: 0,
    dayage: 28,
    history: "",
    hint : "",
    menu2: false,

    loading: false,
    loadingSave: false,

    memoHistory : "",
    memoHistories : [],
    dialogMemo: false,

  }),
  created() {

    EventBus.$on('infoDetail', (event_id) => {this.infoDetail(event_id)});
    EventBus.$on('clearData', () => {this.clearData()});

    this.comboBuildings();
    this.comboRooms();
    this.tocomboBuildings();
    this.tocomboRooms();
    this.eventdate = dateUtil.format(new Date(), "yyyy-MM-dd");

    if (this.$route.params.room && this.$route.params.room.code) {            // 모니터링에서 랜딩
      this.clearData();
      this.building = this.$route.params.building;
      this.room = this.$route.params.room;
    }
  },
  mounted() {
    if (this.$route.params.room && this.$route.params.room.code) this.$refs.room.focus();             // 모니터링에서 랜딩
  },
  methods: {
    clearData() {
      this.event_id = 0,
      this.building = {},
      this.room = {},
      this.eventdate = "",
      this.event = "",
      this.pigcnt = 0,
      this.dayage = 28,
      this.tobuilding = {},
      this.toroom = {},
      this.history = ""
      this.hint = "";
      // this.$refs.room.focus();
    },
    checkData() {

      if (!this.building.code) {
        this.$refs.building.focus();
        return "현재 축사를 선택입력하세요";
      }
      if (!this.room.code) {
        this.$refs.room.focus();
        alert("현재 돈방을 선택입력하세요");
        return "현재 돈방을 선택입력하세요";
      }
      if (!this.eventdate) {
        this.$refs.eventdate.focus();
        alert("발생일을 입력하세요");
        return "발생일을 입력하세요";
      }
      if (this.eventdate > dateUtil.format(new Date(), "yyyy-MM-ddTHH:mm:ss.000+09:00")) {
        alert("날짜를 확인하십시요 \n 미리 입력할 수 없습니다! ");
        this.$refs.eventdate.focus();
        return "날짜를 확인하십시요";
      }
      if (!this.event) {
        this.$refs.event_even.$el.children[1].focus()
        return "입력종류를 선택하세요";
      }
      if (!this.pigcnt) {
        this.$refs.pigcnt.focus();
        alert("개체수를 입력하세요");
        return "개체수를 입력하세요";
      }
      if (this.pigcnt < 1 || this.pigcnt > 500) {
        alert("두수를 확인 입력하세요 \n 음수나 500마리 이상은 입력할 수 없습니다! ");
        this.$refs.pigcnt.focus();
        return "두수를 확인 입력하세요";
      }
      if (this.event == "EVEN" && !this.dayage) {
        this.$refs.dayage.focus();
        alert("입식일령을 입력하세요");
        return "입식일령을 입력하세요";
      }
      if (this.event == "EVTR") {
        if (!this.toroom.code) {
          this.$refs.room.focus();
          return "이동할 돈방을 선택 입력하세요";
        }
        if (this.room.code == this.toroom.code) {
          this.$refs.toroom.focus();
          return "현재 돈방과 이동할 돈방이 동일 합니다.";
        }
      }
      return "";
    },
    save() {
      // console.log("저장");
      this.$store.commit("resMessage","");
      if (this.event_id > 0) {
        if (!confirm("수정 확인 ?\n 자료를 수정하시겠습니까? ")) {
                      return;
        }
      }
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      this.loadingSave = true;

      switch (this.event) {
        case "EVEN":
          this.saveEnter();
          break;
        case "EVTR":
          this.saveTransfer();
          break;
        case "EVDT":
          this.saveDeath();
          break;
        case "EVSD":
          this.saveSale();
          break;
        default:
          alert("이벤트종류 오류");
          this.loadingSave = false;
          return;
      }    
    },
    saveEnter() {
      // console.log("입식저장전체크");
      Apis.pigcntInSection(
        {
          room: this.room.code,
          section: 0,
          stand_dt: this.eventdate,
          event_id: Number(this.event_id),
        },
        res => 
          {  
            this.loadingSave = false;
            if (res.result) {
              if (res.data > 0) {
                if (!confirm("확인 ?\n 입식하려는 방에 이미 " + res.data +"마리의 개체가 있습니다. \n 합방하시렵니까?")) {
                    return;
                }
              }
              this.saveEnterConfirm();
            } else {
              alert(res.message);
            }
          }
      ).catch(err =>
                {  // API 오류 처리
                  this.loadingSave = false;
                  console.log("pigcntInSection API 호출 오류",err)
                  alert(err);
                }
        ) 
    },
    saveEnterConfirm() {
      // 입식입력 저장
      // console.log("입식저장");
      Apis.saveEnterQuick(
        {
          event_id: Number(this.event_id),
          event_cd: "EVEN",
          tocompany: this.room.company_cd,
          tofarm: this.room.farm_cd,
          tobuilding: this.room.building_cd,
          toroom: this.room.code,
          tosection: 0,
          frcompany: "",
          frfarm: "",
          frbuilding: "",
          frroom: "",
          frsection: 0,
          eventdate: this.eventdate,
          pigcnt: Number(this.pigcnt),
          dayage: Number(this.dayage),
          sex: "X",
          history: this.history.trim(),
        },
        res => {    
          this.loadingSave = false;
          if (res.result) {
            alert("입식 : " + res.message);
            this.clearData();
            this.pushPrepage();
          } else {
            this.$store.commit("resMessage",res.message);
            alert("Error Code : " + res.resultCode + "\n" + res.message);
          }
        }
      ).catch(err =>
          {  // API 오류 처리
            this.loadingSave = false;
            console.log("saveEnterQuick API 호출 오류",err)
            alert(err);
          }
        )
    },

    saveTransfer() {
      // console.log("이동전 체크");
      Apis.pigcntInSection(
        {
          room: this.toroom.code,
          section: 0,
          stand_dt: this.eventdate,
          event_id: Number(this.event_id),
        },
        res =>
          {  
            if (res.result) {
              if (res.data > 0) {
                this.loadingSave = false;
                if (!confirm("확인 ?\n 입식하려는 방에 이미 " + res.data +"마리의 개체가 있습니다. \n 합방 이동하시렵니까?")) {
                    return;
                }
              }
              this.saveTransferConfirm();
            } else {
              alert(res.message);
            }
          }
      ).catch(err =>
                {  // API 오류 처리
                  this.loadingSave = false;
                  console.log("pigcntInSection API 호출 오류",err)
                  alert(err);
                }
        ) 
    },
    saveTransferConfirm() {
      Apis.saveTransferQuick(
        {
          event_id: Number(this.event_id),
          event_cd: "EVTR",
          frcompany: this.room.company_cd,
          frfarm: this.room.farm_cd,
          frbuilding: this.room.building_cd,
          frroom: this.room.code,
          frsection: 0, 
          tocompany: this.toroom.company_cd,
          tofarm: this.toroom.farm_cd,
          tobuilding: this.toroom.building_cd,
          toroom: this.toroom.code,
          tosection: 0, 
          eventdate: this.eventdate,
          pigcnt: Number(this.pigcnt),
          sex: "X",
          history: this.history.trim(),
        },
        res => {
          this.loadingSave = false;
          if (res.result) {
            alert("이동 : " + res.message);
            this.clearData();
            this.pushPrepage();
          } else {
            this.$store.commit("resMessage",res.message);
            alert("Error Code : " + res.resultCode + "\n" + res.message);
          }
        }
      ).catch(err =>
          {  // API 오류 처리
            this.loadingSave = false;
            console.log("saveTransferQuick API 호출 오류",err)
            alert(err);
          })
    },

    saveDeath() {
      Apis.saveDeathQuick(
        {
          event_id: Number(this.event_id),
          event_cd: "EVDT",
          tocompany: "",
          tofarm: "",
          tobuilding: "",
          toroom: "",
          tosection: 0,
          frcompany: this.room.company_cd,
          frfarm: this.room.farm_cd,
          frbuilding: this.room.building_cd,
          frroom: this.room.code,
          frsection: 0,
          eventdate: this.eventdate,
          pigcnt: Number(this.pigcnt),
          sex: "X",
          // cause0: "",
          // cause1: "",
          // cause2: "",
          history: this.history.trim(),
       },
        res => {
          this.loadingSave = false;
          if (res.result) {
            alert("도폐 : " + res.message);
            this.clearData();
            this.pushPrepage();
          } else {
            this.$store.commit("resMessage",res.message);
            alert("Error Code : " + res.resultCode + "\n" + res.message);
          }
        }
      ).catch(err =>
          {  // API 오류 처리
            this.loadingSave = false;
            console.log("saveDeathQuick API 호출 오류",err)
            alert(err);
          })
    },

    saveSale() {
      Apis.saveSaleQuick(
        {
          event_id: Number(this.event_id),
          event_cd: "EVSD",
          tocompany: "",
          tofarm: "",
          tobuilding: "",
          toroom: "",
          tosection: 0,
          frcompany: this.room.company_cd,
          frfarm: this.room.farm_cd,
          frbuilding: this.room.building_cd,
          frroom: this.room.code,
          frsection: 0,
          eventdate: this.eventdate,
          pigcnt: Number(this.pigcnt),
          sex: "X",
          // weight_ave: 0,
          // dealcompany: "",
          history: this.history.trim(),
        },
        res => {
          this.loadingSave = false;
          if (res.result) {
            alert("출하 : " + res.message);
            this.clearData();
            this.pushPrepage();
          } else {
            this.$store.commit("resMessage",res.message);
            alert("Error Code : " + res.resultCode + "\n" + res.message);
          }
        }
      ).catch(err =>
          {  // API 오류 처리
            this.loadingSave = false;
            console.log("saveSaleQuick API 호출 오류",err)
            alert(err);
          }
        )
    },
    pushPrepage() {   // 수정 후 목록 셀렉트 유지
      EventBus.$emit('listEvents', this.event_id);
    },

    deleteEvent() {
      if (!confirm("경고 ! \n 삭제 하시겠습니까 ? \n 삭제 후 복구할 수 없습니다, 주의 요망")) {
        return
      }  
      Apis.deleteEvent(
        {
          event_id: this.event_id,
        },
        res => {
          // alert("입식삭제 : " + res.message);
          this.$store.commit("resMessage",res.message);
          if (res.result) {
            this.clearData();
            this.pushPrepage();
          }
        }
      ).catch(err =>
          {  // API 오류 처리
            console.log("deleteEvent API 호출 오류",err)
            alert(err);
          }
        )
    },
    infoDetail(event_id) {
      this.$store.commit("resMessage","");
      this.loading = true;
      this.clearData();
      Apis.infoEvent(
        {
          event_id: event_id, 
        },
        res => 
          {  
            if (res.result) {
              this.event_id = res.data.id;
              this.pigcnt =  res.data.pigcnt;
              this.eventdate = res.data.eventdate;
              this.event = res.data.event_cd;
              this.history = res.data.history;          
              this.hint = res.data.regdate + "에 등록, " + res.data.chguser + "님 " + res.data.chgdate + "최종 수정";
        
              if (res.data.event_cd == "EVEN") {
                  this.building = { company_cd: res.data.tocompany, company_name: res.data.tocompany_name,
                                    farm_cd: res.data.tofarm, farm_name: res.data.tofarm_name,
                                    code: res.data.tobuilding, name: res.data.tobuilding_name};
                  this.room =     { company_cd: res.data.tocompany, company_name: res.data.tocompany_name,
                                    farm_cd: res.data.tofarm, farm_name: res.data.tofarm_name,
                                    building_cd: res.data.tobuilding, building_name: res.data.tobuilding_name,
                                    code: res.data.toroom, name: res.data.toroom_name + " " + res.data.tofarm_name};
              }

              if (res.data.event_cd == "EVTR" || res.data.event_cd == "EVSD" || res.data.event_cd == "EVDT") {
                  this.building = { company_cd: res.data.frcompany, company_name: res.data.frcompany_name,
                                    farm_cd: res.data.frfarm, farm_name: res.data.frfarm_name,
                                    code: res.data.frbuilding, name: res.data.frbuilding_name};
                  this.room =     { company_cd: res.data.frcompany, company_name: res.data.frcompany_name,
                                    farm_cd: res.data.frfarm, farm_name: res.data.frfarm_name,
                                    building_cd: res.data.frbuilding, building_name: res.data.frbuilding_name,
                                    code: res.data.frroom, name: res.data.frroom_name + " " + res.data.frfarm_name};
              }

              if (res.data.event_cd == "EVTR") {
                  this.tobuilding = { company_cd: res.data.tocompany, company_name: res.data.tocompany_name,
                                    farm_cd: res.data.tofarm, farm_name: res.data.tofarm_name,
                                    code: res.data.tobuilding, name: res.data.tobuilding_name};
                  this.toroom =     { company_cd: res.data.tocompany, company_name: res.data.tocompany_name,
                                    farm_cd: res.data.tofarm, farm_name: res.data.tofarm_name,
                                    building_cd: res.data.tobuilding, building_name: res.data.tobuilding_name,
                                    code: res.data.toroom, name: res.data.toroom_name + " " + res.data.tofarm_name};
              }                      
              this.$store.commit("resMessage",res.message);

              this.loading = false;
            } else {  
                this.loading = false;
                alert(res.message);
            }
          }
        ).catch( (err) => {  // API 오류 처리
            this.loading = false;
            console.log("API 호출 오류",err)
            alert(err);
        }
      ) 
    },

    newRegister() {
      this.$store.commit("resMessage","");
      this.clearData();
      this.$refs.room.focus();
      this.$store.commit("resMessage","새로 등록할 준비가 되었습니다.");
    },
    // 현재 농장 선택 콤보
    comboBuildings() {
      Apis.comboBuildings({
        company_cd: "",
        farm_cd:  "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      // if (this.building && this.building.company_cd) {
        this.room = {};
      // }
    },
    comboRooms() {
      Apis.comboRooms({
        company_cd: "",
        farm_cd: "",
        building_cd: this.building && this.building.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.roomItems = [];
            for (let i in res.data) {
            this.roomItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              building_cd: res.data[i].building_cd, 
              building_name: res.data[i].building_name, 
              code: res.data[i].room_cd,
              name: res.data[i].room_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.room && this.room.building_cd) {
        this.building = {code: this.room.building_cd, name: this.room.building_name};
      }
    },
    // 이동 농장 선택 콤보
    tocomboBuildings() {
      Apis.comboBuildings({
        company_cd: "",
        farm_cd:  "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.tobuildingItems = [];
            for (let i in res.data) {
            this.tobuildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    tochangeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      // if (this.tobuilding && this.tobuilding.company_cd) {
        this.toroom = {};
      // }
    },
    tocomboRooms() {
      Apis.comboRooms({
        company_cd: "",
        farm_cd: "",
        building_cd: this.tobuilding && this.tobuilding.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.toroomItems = [];
            for (let i in res.data) {
            this.toroomItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              building_cd: res.data[i].building_cd, 
              building_name: res.data[i].building_name, 
              code: res.data[i].room_cd,
              name: res.data[i].room_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    tochangeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.toroom && this.toroom.building_cd) {
        this.tobuilding = {code: this.toroom.building_cd, name: this.toroom.building_name};
      }
    },
    listMemo() {
      let cdate = new Date();
      Apis.listEventMemos({
        limit_date: new Date(cdate.setDate(cdate.getDate() - 365 )),  // 메모를 가져올 기간 : llimit_date 부터 cdate 까지 (365일 전부터)
        event_cd: this.event && this.event || "" , 
        },(res) => {  
          if (res.result) {
            this.memoHistories = res.data;
          } else {
            this.memoHistories = [];
            this.$store.commit("resMessage",res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("listEventMemos API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    selectMemo() {
      this.history = this.memoHistory;
      this.memoHistory = "";
      this.memoHistories = [];
      this.dialogMemo = false;
    },
    cancelMemo() {
      this.memoHistory = "";
      this.memoHistories = [];
      this.dialogMemo = false;
    },
  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" scoped>
.eventid {
  font-size: 14px;
  letter-spacing: -0.48px;
  color: #c2bfbf;
  border:0;
}
.normal-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.del-btn{
  color: white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #88191a !important;
}
.dialog-btn{
  color:rgb(80, 78, 78) !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #e9e9e9 !important;
}
.plus-btn{
  margin-top: 7px !important;
  color:black !important;
  font-size:24pt;
  font-weight: bold !important;
  background-color: #d2d0d0 !important;
}
#result .v-btn {
  min-width: 33px;
  width: 33px;
  height: 33px;

}
</style>
<style lang="scss">

.my-field input {
    text-align: center;
}
</style>
